<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="省份:">
            <el-select v-model="filters.provinceCode" filterable clearable>
              <el-option v-for="item in provinceSearchOptions" :key="item.id" :label="item.name"
                         :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="履约日期:">
            <el-date-picker @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="图审人员名称:">
            <el-autocomplete value-key="name" v-model="filters.name"
                             @input="(e) => (filters.name = utils.validForbid(e))" placeholder="请输入图审人员名称"
                             @select="handleSelect"
                             :fetch-suggestions="querySearchAsync"></el-autocomplete>
          </el-form-item>
          <el-form-item label="专家类型:">
            <el-select v-model="filters.expertType" clearable>
              <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table id="exportTab" :data="tableData" border style="width: 100%" v-loading="listLoading"
              ref="multipleTable" :highlight-current-row='true'>
      <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
      <el-table-column prop="userName" align="left" label="姓名">
        <template slot-scope="scope">
          <div style="text-align: left;">
            <el-button type="text" @click="getProject(scope.row)">
              <span style="text-align: left;" v-html="formatUserName(scope.row.userName)"></span>
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="areaNums" label="参加县（区）" align="center" sortable></el-table-column>
      <el-table-column prop="projectArea" label="图审总面积（平方米）" align="center" sortable></el-table-column>
      <el-table-column prop="totalProjectNums" label="参与项目总数" align="center" sortable></el-table-column>
      <el-table-column prop="auditProjectNums" label="审核项目总数" align="center" sortable></el-table-column>
      <el-table-column prop="refuseProjectNums" label="拒领项目总数" align="center" sortable>
        <template slot-scope="scope">
          <el-button type="text" @click="getRefuse(scope.row)"> {{ scope.row.refuseProjectNums }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="overDateTaskNum" label="逾期项目总数" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="getOverdueData(scope.row)"> {{ scope.row.overDateTaskNum }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="提交总数" align="center">
		  <template slot-scope="scope">
		    {{ scope.row.userSubmitOpinionNum }}
		  </template>
	  </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="提交强条总数" align="center">
        <template slot-scope="scope">
          {{ scope.row.forceOpinionNum }}
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="提交应条总数" align="center">
        <template slot-scope="scope">
          {{ scope.row.shouldOpinionNum }}
        </template>
      </el-table-column>
      <el-table-column prop="userSubmitOpinionNum" label="提交深度规定" align="center">
        <template slot-scope="scope">
          {{ scope.row.specialOpinionNum }}
        </template>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告审查意见占比 " align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.userOpinionNums }}/{{ scope.row.totalOpinionNums }}
        </template>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告强条意见占比" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.forceOpinionSummaryNum }}/{{ scope.row.projectForceOpinionSummaryNum }}
        </template>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告应条意见占比" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.shouldOpinionSummaryNum }}/{{ scope.row.projectShouldOpinionSummaryNum }}
        </template>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告深度规定占比" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.specialOpinionSummaryNum }}/{{ scope.row.specialTotalOpinionSummaryNum }}
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="项目详情" :visible.sync="dialog.project.show" v-model="dialog.project.show"
               :close-on-click-modal="false" width='96%'>
      <el-table :data="projectTableData"  border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"  ></el-table-column>
        <el-table-column prop="areaName" label="所属区（县）" width='120' align="center"  ></el-table-column>
        <el-table-column prop="projectName" label="审核项目名称" align="center"  ></el-table-column>
        <el-table-column prop="isPrequalification" label="是否预审" width='100' align="center"  >
          <template slot-scope="scope">
            {{ scope.row.isPrequalification ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="projectArea" label="图审面积（平方米）" width='160' align="center"
                         ></el-table-column>
        <el-table-column prop="createTime" label="分配时间" align="center"  ></el-table-column>
        <el-table-column prop="completeTime" label="完成时间" align="center">
          <template v-if="scope.row.completeTime" slot-scope="scope">
            {{ scope.row.completeTime }}
            {{ scope.row.overdueDate < scope.row.completeTime.substring(0, 10) ? ' （ 超 ）' : '' }}
          </template>
        </el-table-column>

<!--        <el-table-column v-if="this.userOpinionDetailList" label="姓名(提交意见数)" width='320'-->
<!--                         align="center">-->
<!--          &lt;!&ndash; 使用 v-for 遍历数组中的每个元素 &ndash;&gt;-->
<!--          <template v-for="item in this.userOpinionDetailList" >-->
<!--            &lt;!&ndash; 在这里添加显示数组元素的内容 &ndash;&gt;-->
<!--            &lt;!&ndash; 例如，假设数组中的每个元素都有一个名字属性 &ndash;&gt;-->
<!--            <span>{{ item.userName+"("+item.userOpinionNums+")" }}</span><br>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="userName" label = "姓名" align="center" width='320'>
<!--          <template slot-scope="scope">-->
<!--            <div style="text-align: center;">-->

<!--              <div v-for="item in scope.row.item.userOpinionDetailList" >{{ item.userName }}</div>-->

<!--            </div>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="userSubmitOpinionNum" label="提交意见数 (深度规定)" align="center">
			<template slot-scope="scope">
			  {{ scope.row.userSubmitOpinionNum }} ( {{ scope.row.specialOpinionNum }} )
			</template>
		</el-table-column>
        <el-table-column prop="userOpinionNums" label="初审报告审查意见占比 (深度规定)" align="center" sortable>
          <template slot-scope="scope">
            {{ scope.row.userOpinionNums }}/{{ scope.row.totalOpinionNums }} ( {{ scope.row.specialOpinionSummaryNum }}/{{ scope.row.specialTotalOpinionSummaryNum }} )
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.project.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="拒领项目" :visible.sync="dialog.refuse.show" v-model="dialog.refuse.show"
               :close-on-click-modal="false" width='50%'>
      <el-table :data="refuseTableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="projectArea" label="图审面积" align="center"></el-table-column>
        <el-table-column prop="refuseReason" label="拒领原因" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.refuse.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="逾期任务" :visible.sync="dialog.overdueData.show" v-model="dialog.overdueData.show"
               :close-on-click-modal="false" width='70%'>
      <el-table :data="overdueDataTableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="createTime" label="分配时间" align="center"></el-table-column>
        <el-table-column prop="completeTime" label="完成时间" align="center"></el-table-column>
        <el-table-column prop="overdueDate" label="到期时间" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.overdueData.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from '../../../util/date'
import Toolbar from '../../components/Toolbar'
import { getButtonList } from '../../promissionRouter'
import {
  getPerformanceReportList,
  getUserRefuseProjectList,
  getUserProjectDetailList,
  getAreaDictionaryList,
  getUserListPage,
  getTaskDetailList
} from '@/api/api'
import Vue from 'vue'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  components: { Toolbar },
  data () {
    return {
      userOpinionDetailList:[],
      utils: '',
      buttonList: [],
      needData: '',
      filters: {
        name: '',
        assignedUserId: undefined,
        bizStartDate: undefined,
        bizEndDate: undefined,
        orderSort: undefined,
        provinceCode: undefined,
        expertType: undefined
      },
      tableData: [],
      projectTableData: [],
      projectTableData1: [],
      refuseTableData: [],
      refuseTableData1: [],
      datatoExport: [],
      datatoExport1: [],
      datatoExport2: [],
      datatoExport3: [],
      overdueDataTableData: [],
      listLoading: false,
      dialog: {
        refuse: {
          show: false
        },
        project: {
          show: false
        },
        overdueData: {
          show: false
        },
      },
      pickerData: [],
      expertOptions: [{
        'id': 62,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 0,
        'value': '驻场图审工程师',
        'sort': 1,
        'remark': ''
      }, {
        'id': 63,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 1,
        'value': '在线图审专家',
        'sort': 2,
        'remark': ''
      }, {
        'id': 162,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 5,
        'value': '技术部专家',
        'sort': 6,
        'remark': ''
      }],//专家类型
      provinceSearchOptions: [],
      isExecuting: false,
      refuseId: '',
      projectId: '',
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []
    this.buttonList = getButtonList(this.$route.path, routers)
    this.getDataList()
    this.init()
  },
  methods: {
    formatUserName (userName) {
      return userName.replace(/,/g, '<br><br>');
    },
    formatUserNames (userName) {
      return userName.replace(/,/g, '<br>');
    },
    callFunction (item) {
      this[item.func].apply(this, item)
    },

    init () {
      // 获取省份
      getAreaDictionaryList({ parentId: 1 }).then(res => {
        this.provinceSearchOptions = res.data.response.reverse()
      })
    },
    // 查询
    getListData () {
      this.getDataList()
    },
    // 列表查询接口
    getDataList () {
      let params = {
        assignedUserId: this.filters.assignedUserId,
        bizEndDate: this.filters.bizEndDate,
        bizStartDate: this.filters.bizStartDate,
        orderSort: this.filters.orderSort,
        provinceCode: this.filters.provinceCode,
        expertType: this.filters.expertType,
      }
      getPerformanceReportList(params).then(res => {
        const result = res.data
        if (result.success) {
          this.tableData = result.response
          this.mianTbaleList = res.data.response
        }
      })
    },
    handleSelect (item) {
      this.filters.assignedUserId = item.id
    },
    querySearchAsync (queryString, cb) {
      getUserListPage({ userName: queryString, pageSize: 5, pageIndex: 1 }).then(res => {
        if (res.data.success) {
          this.userList = res.data.response.data
          let userList = this.userList
          let results = queryString ? userList.filter(this.createStateFilter(queryString)) : userList
          cb(results)
        }
      })
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.name.indexOf(queryString) === 0)
      }
    },
    // 封装执行数组循环的方法
    async dialogDemo () {
      let row = this.mianTbaleList

      for (const item of row) {
        // 执行循环中的操作，这里可以是异步操作
        // console.log(item,'item')
        await this.getRefuse1(item,item.id);
        await this.getProject1(item,item.id);
      }


    },
    async processItems () {
      const flattenedArray = this.refuseTableData1.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
      const flattenedArray1 = this.projectTableData1.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
      if (this.projectTableData1.length >= this.mianTbaleList.length && this.refuseTableData1.length >= this.mianTbaleList.length) {
        // // this.datatoExport = [...flattenedArray1, ...flattenedArray]
        // console.log(this.projectTableData1,'data1')
        for (const item of this.mianTbaleList){
            const customIndex = item.id ;
          // const targetProjectObjects = this.projectTableData1.forEach(item=>{item.filter(obj => customIndex===obj.customIndex)});
              const targetRefuseObjects = this.refuseTableData1.forEach(item => {item.filter(obj => customIndex === obj.customIndex)});

          // 遍历数组并将对象按ID分组
          // this.datatoExport.forEach((obj) => {
          //   const customIndex = item.id ;
          //   const idToObjectMap = [];
          //   if (idToObjectMap[customIndex]) {
          //     idToObjectMap[customIndex].push(obj);
          //   } else {
          //     idToObjectMap[customIndex] = [obj];
          //   }
          //   console.log(idToObjectMap,'cccc')
          // });

        }
        // console.log(this.datatoExport,'xxx')
        // 创建一个对象，用于存储具有相同ID的对象
        // const idToObjectMap = [];

        // 遍历数组并将对象按ID分组
        // this.datatoExport.forEach((obj) => {
        //   const customIndex = ;
        //   if (idToObjectMap[customIndex]) {
        //     idToObjectMap[customIndex].push(obj);
        //   } else {
        //     idToObjectMap[customIndex] = [obj];
        //   }
        // });
        // // 输出具有相同ID的小对象
        // for (const customIndex in idToObjectMap) {
        //   if (idToObjectMap[customIndex].length > 1) {
        //     console.log(`具有相同ID ${customIndex} 的小对象：`);
        //     idToObjectMap[customIndex].forEach((item) => {
        //       console.log(`   ${item.name}`);
        //     });
        //   }
        // }
        // console.log(idToObjectMap,'vvvv')
        // const flattenedArray = this.refuseTableData1.reduce((accumulator, currentArray) => {
        //   return accumulator.concat(currentArray);
        // }, []);
        // const flattenedArray1 = this.projectTableData1.reduce((accumulator, currentArray) => {
        //   return accumulator.concat(currentArray);
        // }, []);
      //   for (const item of this.mianTbaleList) {
      //     const arrayId1= item.id
      //     // console.log(this.projectTableData1,'[')
      //     console.log(this.projectTableData1,3333)
      //     const targetProjectObjects = this.projectTableData1.forEach(item => {item.filter(obj => arrayId1.includes(obj.customIndex))});
      //     const targetRefuseObjects = this.refuseTableData1.forEach(item=>{item.filter(obj => arrayId1.includes(obj.customIndex))});
      //
      //     // console.log(targetProjectObjects,'re')
      //     this.datatoExport = [...targetProjectObjects, ...targetRefuseObjects];
      //     if (this.datatoExport1.length < this.mianTbaleList.length) {
      //       this.datatoExport1.push(this.datatoExport);
      //     }
      //   }
      }
      // console.log(this.datatoExport1,'datatoExport1')
    },

    async exportListData () {
      /* generate workbook object from table */
      // await this.dialogDemo()
      // await this.processItems()
      // console.log(this.datatoExport, 'wwww')
      // 创建工作簿
      // console.log(this.mianTbaleList,'main')
      let wb = XLSX.utils.book_new()
      // 主表的数据
      let mainTable = document.querySelector('#exportTab')
      let xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
      let mainSheet = XLSX.utils.table_to_sheet(mainTable, xlsxParam)
      XLSX.utils.book_append_sheet(wb, mainSheet, '主表名') // 追加主表到工作簿
      // if (this.datatoExport1) {
      //   this.datatoExport2 = this.datatoExport1.map(arrItem => {
      //     return arrItem.map(item => ({
      //       // '名字':item.name||'',
      //       '所属区（县）': item.areaName || '',
      //       '是否预审': item.isPrequalification ? '是' : '否' || '',
      //       '审核项目名称': item.projectName || '',
      //       '分配时间': item.createTime || '',
      //       '完成时间': item.completeTime || '',
      //       '图审面积（平方米）': item.projectArea || '',
      //       '提交意见数': item.userSubmitOpinionNum || '',
      //       '初审报告审查意见占比': `${item.userOpinionNums}/${item.totalOpinionNums}` || '',
      //       '项目姓名': item.projectName || '',
      //       '拒领原因': item.refuseReason || ''
      //       // 添加更多属性...
      //     }))
      //   })
        // console.log(this.datatoExport1, 'aaaa')
        // if (this.datatoExport2.length > 0) {
          // this.datatoExport1.forEach((item, index) => {
          //   // 创建每个子表的数据
          //   let dialogSheet = XLSX.utils.aoa_to_sheet(item);
          //   XLSX.utils.book_append_sheet(wb, dialogSheet, `子表${index + 1}`); // 追加子表到工作簿，使用不同的名称
          // });
          // this.datatoExport2.forEach((smallArray, index) => {
          //   // 创建每个子表的数据
          //   let dialogSheet = XLSX.utils.json_to_sheet(smallArray, { header: ['所属区（县）', '是否预审', '审核项目名称', '分配时间', '完成时间', '图审面积（平方米）', '提交意见数', '初审报告审查意见占比', '项目姓名', '拒领原因'] })
          //   XLSX.utils.book_append_sheet(wb, dialogSheet, `子表${index + 1}`)
          // })
          let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
          try {
            FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '业绩考核.xlsx')
          } catch (e) {
            if (typeof console !== 'undefined') {
              console.log(e, wbout)
            }
          }
          return wbout
        // } else {
        //   console.log('this.datatoExport 数组为空，无法导出数据。')
        //   return null // 返回 null 或其他适当的值，表示导出失败或没有数据可导出
        // }

    },
    getRefuse (row) {
      getUserRefuseProjectList({ taskIds: row.refuseProjectTaskIds }).then(res => {
        if (res.data.success) {
          this.refuseTableData = res.data.response
          this.dialog.refuse.show = true
        }
      })
    },

    async getRefuse1 (row,index) {
      getUserRefuseProjectList({ taskIds: row.refuseProjectTaskIds }).then(res => {
        // if (res.data.success) {
        let array1 = res.data.response
        let customIndex = index; // 设置自定义下标的初始值
        if (this.refuseTableData1.length < this.mianTbaleList.length) {
          // 使用map函数为array1添加自定义下标值
          array1 = array1.map(item => {
            const customIndexValue = `${customIndex}`;
            return { ...item, customIndex: customIndexValue };
          });
          this.refuseTableData1.push(array1)
        }
      })

      // console.log(this.refuseTableData1)
    },
    async getProject1 (row,index) {
      getUserProjectDetailList({ taskIds: row.auditProjectTaskIds }).then(res => {
        let array2 = res.data.response
        let customIndex = index; // 设置自定义下标的初始值
        if (this.projectTableData1.length < this.mianTbaleList.length) {
          array2 = array2.map(item => {
            const customIndexValue = `${customIndex}`;
            return { ...item, customIndex: customIndexValue };
          });
          this.projectTableData1.push(array2)
        }

      })
    },
    getOverdueData (row) {
      getTaskDetailList({ taskIds: row.overDateTaskIds }).then(res => {
        if (res.data.success) {
          this.overdueDataTableData = res.data.response
          this.dialog.overdueData.show = true
        }
      })
    },
    getProject (row) {
      getUserProjectDetailList({ taskIds: row.auditProjectTaskIds }).then(res => {
        if (res.data.success) {
          this.projectTableData = res.data.response
          // console.log(this.projectTableData,111)
          this.projectTableData.forEach(item => {
            item.userName = row.userName
             this.userOpinionDetailList = item.userOpinionDetailList
            item.userLevelName = row.userLevelName
          })
          this.dialog.project.show = true
        }
      })
    },
    // 日期选择
    changePicker (data) {
      if (data !== null) {
        this.filters.bizStartDate = data[0]
        this.filters.bizEndDate = data[1]
      } else {
        this.filters.bizStartDate = undefined
        this.filters.bizEndDate = undefined
      }
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ''
          ? ''
          : util.formatDate.format(new Date(row.NoticeTime), 'yyyy-MM-dd hh:mm')
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
